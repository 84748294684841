class Renderer {
    constructor(creditCardRenderer, defaultConfig) {
        this.defaultConfig = defaultConfig;
        this.creditCardRenderer = creditCardRenderer;
    }

    render(wrapper, hostedFieldsWrapper, contextConfig) {

        this.renderButtons(wrapper, contextConfig);
        this.creditCardRenderer.render(hostedFieldsWrapper, contextConfig);
    }

    renderButtons(wrapper, contextConfig) {
        if (! document.querySelector(wrapper) || this.isAlreadyRendered(wrapper) || 'undefined' === typeof paypal.Buttons ) {
            return;
        }

        let style = wrapper === this.defaultConfig.button.wrapper ? this.defaultConfig.button.style : this.defaultConfig.button.mini_cart_style;

        Object.values(paypal.FUNDING).forEach(function(fundingSource){
            if (!PayPalCommerceGateway.button.disable_funding.includes(fundingSource)){
                if (fundingSource !== paypal.FUNDING.PAYPAL){
                    delete style.color;
                }
                let pp_button = paypal.Buttons({
                    style,
                    fundingSource: fundingSource,
                    ...contextConfig,
                });

                if (pp_button.isEligible()) {
                    pp_button.render(wrapper);
                }
            }
        });
    }

    isAlreadyRendered(wrapper) {
        return document.querySelector(wrapper).hasChildNodes();
    }

    hideButtons(element) {
        const domElement = document.querySelector(element);
        if (! domElement ) {
            return false;
        }
        domElement.style.display = 'none';
        return true;
    }

    showButtons(element) {
        const domElement = document.querySelector(element);
        if (! domElement ) {
            return false;
        }
        domElement.style.display = 'block';
        return true;
    }
}

export default Renderer;
